import React from "react"
import { graphql, Link } from "gatsby"

import { GatsbyImage } from "gatsby-plugin-image"
import { Layout } from "../components/layout"

const WorkProjectTemplate = ({ data, location, pageContext }) => {
  const {
    markdownRemark: { html, frontmatter },
  } = data

  return (
    <Layout
      location={location}
      title={frontmatter.title + " | Work"}
      className="page-work-project"
      showScrollTop="true"
    >
      <h1 className="title">{frontmatter.title}</h1>
      <div className="content">
        <div className="about">
          <h3>My Role</h3>
          <p>{frontmatter.roleinproject}</p>
          <h3>Project Type</h3>
          <p>{frontmatter.projecttype}</p>
        </div>
        <div
          className="description"
          dangerouslySetInnerHTML={{ __html: html }}
        ></div>
      </div>
      <ul className="collection-images">
        {(data.markdownRemark.frontmatter.images || []).map(
          ({ image, alt, imageheader, imagecontent }, id) => (
            <li key={id}>
              {imageheader && <h3>{imageheader}</h3>}
              {imagecontent?.html && (
                <div
                  dangerouslySetInnerHTML={{ __html: imagecontent.html }}
                ></div>
              )}
              {image && (
                <GatsbyImage
                  image={image.childImageSharp.gatsbyImageData}
                  layout="contstrained"
                  alt={alt}
                />
              )}
            </li>
          ),
        )}
      </ul>
      <div className="navigation">
        <Link to={"/" + pageContext.prev.fields.slug}>Previous</Link>
        <Link to={"/" + pageContext.next.fields.slug}>Next</Link>
      </div>
    </Layout>
  )
}

export default WorkProjectTemplate

export const pageQuery = graphql`
  query ProjectBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      html
      frontmatter {
        title
        description
        roleinproject
        projecttype
        images {
          alt
          image {
            childImageSharp {
              gatsbyImageData(
                layout: CONSTRAINED
                width: 940
                height: 564
                quality: 95
                placeholder: TRACED_SVG
              )
            }
          }
          imagecontent {
            html
          }
          imageheader
        }
      }
    }
  }
`
